import React, { useEffect, useState } from "react";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { History } from "history";
import { process } from "@progress/kendo-data-query";

import { IImage } from "../../models/image";
import { IIssue } from "../../models/issue";
import { IQcCheckpoint } from "../../models/QualityControl/qcCheckpoint";
import { IQcInspection } from "../../models/QualityControl/qcInspection";
import { IUser } from "../../models/user";
import Aux from "../../utils/auxiliary";
import QcCheckpointImageModal from "../QualityControl/QcCheckpoint/QcCheckpointImageModal";
import IssueCheckpointCard from "./IssueCheckpointCard";
import { IAuditEntry } from "models/audit";
import AuditModal from "components/Audit/AuditModal";
import { Button, Col, Row } from "react-bootstrap";

export interface IProps {
  issue: IIssue;
  inspections: IQcInspection[];
  checkpoints: IQcCheckpoint[];
  jigCheckpoints: IQcCheckpoint[];
  newCheckpoints: IQcCheckpoint[];
  inspectionsAudits: IAuditEntry[];
  currentUser: IUser;
  history: History;
  handleItemSelected: (selected: any) => any;
  setIssueClone: (issue: IIssue) => any;
  editCaseInspection: (inspection: IQcInspection) => any;
}

export const IssueChecklistForm: React.FC<IProps> = (props) => {
  const {
    issue,
    checkpoints,
    jigCheckpoints,
    newCheckpoints,
    inspections,
    inspectionsAudits,
    currentUser,
    history,
    handleItemSelected,
    setIssueClone,
    editCaseInspection,
  } = props;

  const [issueInspections, setIssueInspections] = React.useState<
    IQcInspection[]
  >([]);

  const [audits, setAudits] = useState<IAuditEntry[] | null>(null);

  const [search, setSearch] = useState<string>("");

  const [viewImage, setViewImage] = useState<IImage>();

  const [dataState] = useState({
    group: [{ field: "tagNames" }],
  });

  const [jigDataState] = useState({
    group: [{ field: "jigNr" }],
  });

  useEffect(() => {
    setIssueInspections(inspections);
    setIssueClone({ ...issue, inspections: inspections });
  }, [inspections]); // eslint-disable-line react-hooks/exhaustive-deps

  const [text, setText] = useState("");
  const [
    changedInspection,
    setChangedInspection,
  ] = useState<IQcInspection | null>(null);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (changedInspection !== null) {
        editCaseInspection(changedInspection);
        setChangedInspection(null);
        setText("");
      }
    }, 3000);
    return () => clearTimeout(timeOutId);
  }, [text, changedInspection]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckboxChange = (value: boolean, checkpoint: IQcCheckpoint) => {
    let tempIssue = { ...issue, inspections: issueInspections };
    // if it's checked
    if (value) {
      const foundInspection = tempIssue.inspections.find(
        (x) => x.qcCheckpointId === checkpoint.id
      );
      if (foundInspection === undefined) {
        const newInspection = {
          qcCheckpointId: checkpoint.id,
          qcCheckpointName: checkpoint.name,
          userId: currentUser.id,
          inspector: currentUser,
          issueId: issue.id,
          qcStatusId: 2,
          userName: currentUser.fullName,
          updatedAt: new Date(),
          images: [] as IImage[],
        } as IQcInspection;
        tempIssue.inspections.push(newInspection);
        if (issue.id > 0) editCaseInspection(newInspection);
      } else {
        foundInspection.qcStatusId = 2;
        foundInspection.userId = currentUser.id;
        foundInspection.userName = currentUser.fullName;
        foundInspection.updatedAt = new Date();
        if (issue.id > 0) editCaseInspection(foundInspection);
      }
    }
    // if it's not checked
    if (!value) {
      tempIssue.inspections.forEach((inspection) => {
        if (inspection.qcCheckpointId === checkpoint.id) {
          inspection.qcStatusId = 1;
          if (issue.id > 0) editCaseInspection(inspection);
        }
      });
    }

    setIssueInspections(tempIssue.inspections);
    setIssueClone(tempIssue);
  };

  const handleNoteChange = (value: string, checkpoint: IQcCheckpoint) => {
    let tempIssue = { ...issue, inspections: issueInspections };
    const foundInspection = tempIssue.inspections.find(
      (x) => x.qcCheckpointId === checkpoint.id
    );
    if (foundInspection === undefined) {
      const newInspection = {
        qcCheckpointId: checkpoint.id,
        userId: currentUser.id,
        inspector: currentUser,
        qcStatusId: 0,
        note: value,
        images: [] as IImage[],
      } as IQcInspection;
      tempIssue.inspections.push(newInspection);
      if (issue.id > 0) {
        if (changedInspection !== null) {
          setChangedInspection({ ...changedInspection, note: value });
        } else {
          setChangedInspection(newInspection);
        }
      }
    } else {
      foundInspection.note = value;
      if (issue.id > 0) {
        if (changedInspection !== null) {
          setChangedInspection({ ...changedInspection, note: value });
        } else {
          setChangedInspection(foundInspection);
        }
      }
    }
    setIssueInspections(tempIssue.inspections);
    setIssueClone(tempIssue);
  };

  const handleNotApplicableChange = (
    value: boolean,
    checkpoint: IQcCheckpoint
  ) => {
    let tempIssue = { ...issue, inspections: issueInspections };
    const foundInspection = tempIssue.inspections.find(
      (x) => x.qcCheckpointId === checkpoint.id
    );
    if (foundInspection === undefined) {
      const newInspection = {
        qcCheckpointId: checkpoint.id,
        userId: currentUser.id,
        inspector: currentUser,
        userName: currentUser.fullName,
        notApplicable: value,
        qcStatusId: 0,
        updatedAt: new Date(),
        images: [] as IImage[],
      } as IQcInspection;
      tempIssue.inspections.push(newInspection);
      if (issue.id > 0) editCaseInspection(newInspection);
    } else {
      foundInspection.notApplicable = value;
      foundInspection.userId = currentUser.id;
      foundInspection.userName = currentUser.fullName;
      foundInspection.updatedAt = new Date();
      if (issue.id > 0) editCaseInspection(foundInspection);
    }
    setIssueInspections(tempIssue.inspections);
    setIssueClone(tempIssue);
  };

  const handleFileAdd = (file: IImage, checkpoint: IQcCheckpoint) => {
    let tempIssue = { ...issue, inspections: issueInspections };
    const foundInspection = tempIssue.inspections.find(
      (x) => x.qcCheckpointId === checkpoint.id
    );
    if (foundInspection === undefined) {
      const newInspection = {
        qcCheckpointId: checkpoint.id,
        userId: currentUser.id,
        inspector: currentUser,
        qcStatusId: 0,
        images: [file],
      } as IQcInspection;
      tempIssue.inspections.push(newInspection);
      if (issue.id > 0) editCaseInspection(newInspection);
    } else {
      foundInspection.images.push(file);
      if (issue.id > 0) editCaseInspection(foundInspection);
    }
    setIssueInspections(tempIssue.inspections);
    setIssueClone(tempIssue);
  };

  const handleFileRemove = (
    file: UploadFileInfo,
    checkpoint: IQcCheckpoint
  ) => {
    let tempIssue = { ...issue, inspections: issueInspections };
    tempIssue.inspections.forEach((inspection, index) => {
      if (inspection.qcCheckpointId === checkpoint.id) {
        inspection.images = inspection.images.filter(
          (i) => !i.name.includes(file.name)
        );
        setIssueInspections(tempIssue.inspections);
        setIssueClone(tempIssue);
        if (issue.id > 0) editCaseInspection(inspection);
      }
    });
  };

  const handleImageRemove = (file: IImage, checkpoint: IQcCheckpoint) => {
    let tempIssue = { ...issue, inspections: issueInspections };
    tempIssue.inspections.forEach((inspection, index) => {
      if (inspection.qcCheckpointId === checkpoint.id) {
        inspection.images = inspection.images.filter(
          (i) => !i.name.includes(file.name)
        );
        if (issue.id > 0) editCaseInspection(inspection);
        setIssueInspections(tempIssue.inspections);
        setIssueClone(tempIssue);
      }
    });
  };

  const isNew = (checkpoint: IQcCheckpoint) => {
    return newCheckpoints.find((c) => c.id === checkpoint.id) ? true : false;
  };

  return (
    <Aux>
      {checkpoints && checkpoints.length > 0 && (
        <div className="form-group m-0">
          <Row>
            <Col className="pr-0" md={11} sm={10} xs={10}>
              <input
                type={"text"}
                placeholder="Filter checkpoints"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="form-control"
              />
            </Col>
            <Col md={1} sm={2} xs={2}>
              <Button
                title="Clear filter"
                onClick={() => setSearch("")}
                className={
                  "border-0 p-0 " +
                  (search.length > 0 ? "text-white" : "text-muted")
                }
                style={{ cursor: "pointer", background: "transparent" }}
                disabled={search.length === 0}
              >
                <span
                  className="k-icon k-i-filter-clear"
                  style={{ fontSize: "32px" }}
                ></span>
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <div>
        {checkpoints &&
          checkpoints.length > 0 &&
          process(
            checkpoints.filter((c) =>
              c.name.toLowerCase().includes(search.toLowerCase())
            ),
            dataState
          )
            .data.sort((a, b) =>
              a.value ? (b.value ? a.value.localeCompare(b.value) : -1) : 1
            )
            .map((i) => {
              return (
                <div key={i.value}>
                  <h6>{i.value || "Case Checkpoints"}</h6>
                  {i.items
                    .map((c: IQcCheckpoint) => {
                      var checkpointTag = c.tagCheckpoints.find(
                        (t) => t.tagName === i.value
                      );
                      if (checkpointTag !== undefined) {
                        c.sort = checkpointTag.sort;
                      }
                      return c;
                    })
                    .sort((a, b) => a.sort - b.sort)
                    .map((checkpoint) => {
                      let foundInspection = issueInspections.find(
                        (i) => i.qcCheckpointId === checkpoint.id
                      );
                      return (
                        <IssueCheckpointCard
                          key={checkpoint.id}
                          issue={issue}
                          checkpoint={checkpoint}
                          inspection={foundInspection}
                          inspectionsAudits={inspectionsAudits}
                          currentUser={currentUser}
                          isNew={isNew(checkpoint)}
                          history={history}
                          disabled={changedInspection !== null}
                          handleItemSelected={handleItemSelected}
                          onCheckboxChange={(v) =>
                            handleCheckboxChange(v, checkpoint)
                          }
                          onNoteChange={(v) => handleNoteChange(v, checkpoint)}
                          viewImage={(e) => setViewImage(e)}
                          setAudits={setAudits}
                          handleNotApplicableChange={handleNotApplicableChange}
                          handleFileAdd={handleFileAdd}
                          handleFileRemove={handleFileRemove}
                          handleImageRemove={handleImageRemove}
                          editCaseInspection={editCaseInspection}
                        />
                      );
                    })}
                </div>
              );
            })}
      </div>
      {issue.jigId !== undefined && issue.jigId > 0 && (
        <div className="mt-3 mb-5">
          {jigCheckpoints && jigCheckpoints.length > 0
            ? process(
                jigCheckpoints.filter((c) =>
                  c.name.toLowerCase().includes(search.toLowerCase())
                ),
                jigDataState
              ).data.map((i) => {
                return (
                  <div key={i.value}>
                    <h6>Equipment {i.value} checklist</h6>
                    {i.items.map((checkpoint) => {
                      let foundInspection = issueInspections.find(
                        (i) => i.qcCheckpointId === checkpoint.id
                      );
                      return (
                        <IssueCheckpointCard
                          key={checkpoint.id}
                          issue={issue}
                          checkpoint={checkpoint}
                          inspection={foundInspection}
                          inspectionsAudits={inspectionsAudits}
                          currentUser={currentUser}
                          isNew={isNew(checkpoint)}
                          history={history}
                          disabled={changedInspection !== null}
                          handleItemSelected={handleItemSelected}
                          onCheckboxChange={(v) =>
                            handleCheckboxChange(v, checkpoint)
                          }
                          onNoteChange={(v) => handleNoteChange(v, checkpoint)}
                          viewImage={(e) => setViewImage(e)}
                          handleNotApplicableChange={handleNotApplicableChange}
                          handleFileAdd={handleFileAdd}
                          setAudits={setAudits}
                          handleFileRemove={handleFileRemove}
                          handleImageRemove={handleImageRemove}
                          editCaseInspection={editCaseInspection}
                        />
                      );
                    })}
                  </div>
                );
              })
            : "No checkpoints for this equipment"}
        </div>
      )}
      <QcCheckpointImageModal
        handleHide={() => setViewImage(undefined)}
        view={viewImage !== undefined}
        image={viewImage}
      />
      {audits !== null && (
        <AuditModal
          entries={audits}
          onClose={() => setAudits(null)}
          history={history}
          show={audits !== null}
          title={"Inspection Audit Log"}
        />
      )}
    </Aux>
  );
};

export default IssueChecklistForm;
